
import { Component, Prop, Vue } from "vue-property-decorator";
import MPActivationFlow from "@/components/landingPages/marketplace/gcp/MPActivationFlow.vue";
import { isRestClientError, MPSelfHostedRequest, storeErrorsMapping } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "MPWaitingRoom",
  components: { MPActivationFlow },
})
export default class MPWaitingRoom extends Vue {
  isDelayed: boolean = false;
  isReady: boolean = false;
  isFatal: boolean = false;
  request!: MPSelfHostedRequest;

  created() {
    this.loadPreviousFormData();
  }

  mounted() {
    setTimeout(() => {
      this.checkStatus();
    }, 2 * 60 * 1000);
  }
  loadPreviousFormData() {
    const request = sessionStorage.getItem("request");
    if (request) {
      this.request = JSON.parse(request);
    }
  }
  async checkStatus() {
    try {
      const statusResponse = await this.marketplaceShStatus();
      this.isReady = true;
      return statusResponse;
    } catch (e) {
      if (isRestClientError(e) && e.httpBody) {
        const res = e.httpBody;
        if (res.result === storeErrorsMapping.marketplaceSubmit.gcpEntityNotificationsFailedIsEmptyError) {
          this.isFatal = true;
          this.isReady = false;
        } else if (res.result === storeErrorsMapping.marketplaceSubmit.gcpEntityNotificationsFailedError) {
          this.isDelayed = true;
          this.isReady = false;
        }
      }
    }
  }
  private async marketplaceShStatus() {
    const request = {
      mpToken: this.request.mpToken,
      mpOrigin: this.request.mpOrigin,
    };
    return this.$jfRegistrations.registerSelfHostedStatus(request);
  }
}
