
import CircleSpinnerToCheckmark from "@/components/spinners/CircleSpinnerToCheckmark.vue";
import FadeSpinner from "@/components/spinners/FadeSpinner.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import SimpleIconTitle from "@/components/common/SimpleIconTitle.vue";
import SimpleAlert from "@/components/common/SimpleAlert.vue";
import ProgressBar from "@/components/common/ProgressBar.vue";
import { MPSelfHostedRequest } from "@jfrog-ba/myjfrog-common";

@Component({
  name: "MPActivationFlow",
  components: {
    CircleSpinnerToCheckmark,
    FadeSpinner,
    SimpleIconTitle,
    SimpleAlert,
    ProgressBar,
  },
})
export default class MPActivationFlow extends Vue {
  @Prop() isReady = false;
  @Prop() isDelayed = false;
  @Prop() isFatal = false;
  progressBarPercent: number = 0;
  progressBarMessage = "This process might take about a minute...";
  delayMinutes = 2;
  delaySeconds: number = (this.delayMinutes * 60) / 100;
  storageKey = "mpActivation";
  request!: MPSelfHostedRequest;
  progressBar = true;

  mounted() {
    this.setProgressBarInitialValue();
    this.progressBarPercent = 0;
    this.increaseProgressBarPercent();
  }

  setProgressBarInitialValue() {
    let progressBarInitial = sessionStorage.getItem(this.storageKey);
    if (progressBarInitial && parseInt(progressBarInitial) > 0) {
      this.progressBarPercent = parseInt(progressBarInitial);
    } else {
      this.progressBarPercent = 0;
    }
  }
  get titleEndProgressText() {
    return "Congratulations!";
  }
  get subtitleEndProgressText() {
    return "We successfully generated your license/s. The license owner will receive an email shortly";
  }
  get titleEndProgressTextDelayed() {
    return "We're sorry";
  }
  get subtitleEndProgressTextDelayed() {
    return "We’re sorry, an error occurred while generating your license/s. We’re working to resolve this issue and we’ll update you via email when it’s resolved. If you do not receive an email within 24 hrs, contact your account manager.";
  }
  get titleEndProgressTextFatal() {
    return "We're sorry";
  }
  get subtitleEndProgressTextFatal() {
    return "We’re sorry, an error occurred while generating your license/s. Please contact your account manager.";
  }
  get titleText() {
    return "Ready for your DevOps journey?";
  }
  get subtitleText() {
    return "Please hang on while we process your request.";
  }

  increaseProgressBarPercent() {
    if (this.progressBarPercent > 99 || this.isReady) {
      this.progressBar = false;
    } else {
      this.progressBarPercent++;
    }
    sessionStorage.setItem(this.storageKey, this.progressBarPercent.toString());
    setTimeout(() => {
      this.increaseProgressBarPercent();
    }, this.delaySeconds * 1000);
  }
}
